import React, { FC, PropsWithChildren, memo } from 'react';
import { Box, Tooltip } from '@bridebook/ui';
import { styles } from './popover-menu.style';

interface PopoverMenuProps
  extends PropsWithChildren,
    Pick<
      Parameters<typeof Tooltip>[0],
      'orientation' | 'minWidth' | 'maxWidth' | 'maxHeight' | 'renderTrigger' | 'onToggle'
    > {}

const PopoverMenuComp: FC<PopoverMenuProps> = ({
  orientation = 'bottom-right',
  renderTrigger,
  minWidth = 250,
  maxWidth = 300,
  maxHeight = 400,
  children,
  onToggle,
}) => (
  <Tooltip
    onToggle={onToggle}
    minWidth={minWidth}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
    orientation={orientation}
    renderTrigger={(isOpened) => renderTrigger?.(isOpened)}
    type="click"
    theme="light"
    noPadding
    mode="popover"
    data-name="popover-menu">
    <Box style={styles.wrapper}>{children}</Box>
  </Tooltip>
);

/**
 * Universal popover menu for any simple or complex content.
 */
export const PopoverMenu = memo(PopoverMenuComp);
