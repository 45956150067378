import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors, spacing } from '../../../themes/variables';
import { TOOLTIP_BORDER_RADIUS, TOOLTIP_WRAPPER_PADDING } from '../tooltip/tooltip.style';

interface IStyles {
  wrapper: FelaCSS;
  itemWrap: (props: {
    isHighlighted?: boolean;
    isInteractive?: boolean;
    isBold?: boolean;
  }) => FelaCSS;
  iconWrap: FelaCSS;
  content: FelaCSS;
  header: FelaCSS;
  divider: FelaCSS;
}

// Border radius of tooltip container minus inner padding, for accurate radius effect
const ITEM_BORDER_RADIUS = TOOLTIP_BORDER_RADIUS.popover - TOOLTIP_WRAPPER_PADDING;

export const styles: IStyles = {
  wrapper: {
    gap: '2px',
  },

  itemWrap: ({ isHighlighted, isInteractive, isBold }) => ({
    flexShrink: 0,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing[2],
    borderRadius: ITEM_BORDER_RADIUS,
    lineHeight: '18px',
    color: colors.space,

    ...(isBold
      ? {
          fontDefaultSemiBold: 14,
        }
      : { fontDefault: 14 }),

    ...(isHighlighted && {
      background: colors.space04,
    }),

    ...(isInteractive && {
      cursor: 'pointer',

      ':hover': {
        background: colors.space04,
      },
    }),
  }),

  iconWrap: {
    minWidth: 18,
    minHeight: 26,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },

  content: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    maxWidth: '100%',
  },

  header: {
    color: colors.space,
    fontDefaultSemiBold: 14,
    paddingVertical: 2,
    paddingHorizontal: 3,
  },

  divider: {
    width: `calc(100% - 2 * ${spacing[2]})`,
    marginHorizontal: 2,
    marginVertical: 1,
    height: 1,
    background: colors.space08,
  },
};
